import Axios from "axios"

const domain = "https://rsscrawl.herokuapp.com"
// const domain = "http://localhost:8001"

class FeedService {
  fetch(category = "external", page = 1, limit = 10) {
    console.log("page222", page)
    const categoryId = category == "external" ? 5 : 6

    return new Promise((resolve, reject) => {
      Axios.get(
        `${domain}/api/category/${categoryId}?page=${page}&limit=${limit}`
      ).then(response => {
        resolve(response.data)
      })
    })
  }
}

export default FeedService

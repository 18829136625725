import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"

class NewsItem extends React.Component {
  render() {
    if (!this.props.article) {
      return null
    }

    const { article } = this.props

    return (
      <div
        className={`feed-item ${this.props.dark ? "feed-item--dark" : null}`}
      >
        {article.date ? (
          <div className="top">
            <div className="feed-avatar-no-image"></div>
            <div className="feed-name">{article.date}</div>
          </div>
        ) : null}
        <a href={article.url} target="_blank" rel="noopener noreferrer">
          <div
            className={article.image ? "image" : "no-image"}
            style={{ backgroundImage: "url(" + article.image + ")" }}
          >
            <div className="title">{article.name}</div>
          </div>
        </a>
        <div className="excerpt">
          {/* {article.description} */}
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className="prose"
            children={article.description}
            linkTarget={article.currentTab ? "_self" : "_blank"}
          />
        </div>
        <div className="read-more">
          {article.downloadReport ? (
            <a href="/analytics">Download Report</a>
          ) : (
            <a href={article.url} target="_blank" rel="noopener noreferrer">
              Read More
            </a>
          )}
        </div>
      </div>
    )
  }
}

export default NewsItem

import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Modal from "react-modal"

import {
  Group,
  Col,
  Section,
  CtaButton,
  FeedItem,
  VideoEmbed,
} from "../components/common"

import decemberPdf from "../pdfs/S-Factor_Newsletter_Dec2019.pdf"
import janPdf from "../pdfs/S-Factor_Newsletter_Jan2020.pdf"
import febPdf from "../pdfs/S-Factor_Newsletter_Feb2020.pdf"
import marchPdf from "../pdfs/S-Factor_Newsletter_March2020.pdf"
import aprilPdf from "../pdfs/S-Factor_Newsletter_April2020.pdf"
import mayPdf from "../pdfs/S-Factor_Newsletter_May2020.pdf"
import junePdf from "../pdfs/S-Factor_Newsletter_June2020.pdf"
import julyPdf from "../pdfs/S-Factor_Newsletter_July2020.pdf"
import AugSeptPdf from "../pdfs/S-Factor_Newsletter_AugSept2020.pdf"
import OctPdf from "../pdfs/S-Factor_Newsletter_October2020.pdf"

import pr1 from "../pdfs/press-releases/1.pdf"
import pr2 from "../pdfs/press-releases/2.pdf"
import pr3 from "../pdfs/press-releases/3.pdf"
import pr4 from "../pdfs/press-releases/4.pdf"
import pr5 from "../pdfs/press-releases/5.pdf"
import pr6 from "../pdfs/press-releases/6.pdf"
import pr7 from "../pdfs/press-releases/7.pdf"

import findingTheS from "../images/reports/findingTheS.jpg"

import aiNlp from "../images/reports/aiNlp.jpg"
import socialData from "../images/reports/socialData.jpg"
import blendingAlt from "../images/reports/blendingAlt.jpg"

import FeedService from "../services/feed"
import TopContainer from "../components/TopContainer"

import img from "../images/stock/media1.jpg"
import NewsItem from "../components/NewsItem"
import { chunkArray } from "../utils"

const eventItems = [
  {
    date: "Event: December 9-10, 2021 (Chicago)",
    name: "Gender Equality in the C-Suite & Boardroom",
    description:
      "Together with sponsors [Women Get on Board](https://www.womengetonboard.ca/), [League of Allies](https://www.leagueofallies.com/), [Thompson Hine](https://www.thompsonhine.com/), [Breakthrough Results](https://www.breakthroughresults.org/), [Investor.com](https://investor-com.com/), boldly., and [The Robert F. Kennedy Human Rights Foundation](https://rfkhumanrights.org/), The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss the latest developments on the road to gender diversity and best practices on gender parity efforts.",
    url: "https://skytopstrategies.com/conferences/395",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/07/THE-ONE-FOR-CHICAGO.jpeg",
  },
  {
    date: "Event: January 27, 2022 (New York)",
    name: "Shareholder Activism: Unlocking Shareholder Value ",
    description:
      "Together with sponsors, [Wachtell Lipton Rosen & Katz](https://www.wlrk.com/), [Schulte Roth & Zabel](https://www.srz.com/), [Finsbury](https://fgh.com/), [Morgan Stanley](https://www.morganstanley.com/), [FTI Consulting](https://www.fticonsulting.com/), [Edelman](https://www.edelman.com/), [Sidley](https://www.sidley.com/en/eu/), [Investor.com](https://investor-com.com/), [MacKenzie Partners Inc](http://www.mackenziepartners.com/)., [Borden Ladner Gervais](https://www.blg.com/), [Sloane & Company](https://www.sloanepr.com/), [Davies](https://www.dwpv.com/en), [Kleinberg Kaplan](https://www.kkwc.com/), [The Robert F. Kennedy Human Rights Foundation](https://rfkhumanrights.org/), CSR Hub, boldy., [KEKST](https://www.kekstcnc.com/), The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss how activist investors cultivate relationships with companies to positively influence sustained value creation.",
    url: "https://skytopstrategies.com/conferences/391",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/07/the-onee-for-ny.jpg",
  },

  {
    date: "Event: February 22, 2022 (New York)",
    name: "The 21st Century Company How it Creates Value – And for Whom",
    description:
      "Together with sponsors Monet Alternative Asset Management, [Breakthrough Results](https://www.breakthroughresults.org/), boldly., The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss unique approaches to exploiting the internal and external drivers that will reset stakeholder expectations, shareholder engagements, and the successful creation of long-term value.",
    url: "https://skytopstrategies.com/conferences/397",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/07/belvedere-castle.adapt_.1900.1.jpg",
  },
  {
    date: "Event: April 5, 2022 (New York)",
    name: "ESG 6: A Driver of Shareholder Value ",
    description:
      "Together with sponsors [Accountability](https://www.accountability.org/), [Blue Tractor](https://www.bluetractorgroup.com/), [S&P Global](https://www.spglobal.com/esg/trucost), [Baker MacKenzie](https://www.bakermckenzie.com/en), [Moody's ESG Solutions](https://www.moodys.com/), [EEI](https://www.eei.org/Pages/default.aspx), [FigBytes](https://figbytes.com/), boldly., CSR Hub, and [The Robert F. Kennedy Human Rights Foundation](https://rfkhumanrights.org/), The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss emerging methodologies in assessing and presenting ESG performance to the capital markets.",
    url: "https://skytopstrategies.com/conferences/352",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/07/highline.jpeg",
  },
  {
    date: "Event: May 22, 2022 (Boston)",
    name:
      "Impact Investing: Driving Social Purpose Through Measurable Investment Returns",
    description:
      "Together with sponsors [Baker MacKenzie](https://www.bakermckenzie.com/en), [Impact Shares](https://www.impactetfs.org/), [Calvert Impact Capital](https://www.calvertimpactcapital.org/), [NorthStar Asset Management](https://northstarasset.com/), [Developing World Markets](https://dwmarkets.com/), [CAASA](https://caasa.ca/), [Canopy](https://canopyplanet.org/), [GRESB](https://gresb.com/nl-en/), The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss how investors and foundations are changing their investment strategy to include sustainable allocations while still maximizing returns.",
    url: "https://skytopstrategies.com/conferences/224",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/07/boston.jpeg",
  },
  {
    date: "Event: July 28, 2022 (New York)",
    name:
      "Human Rights Risk: Expectations and Emerging Standards for Public Companies",
    description:
      "Together with sponsors, [The Institute for Crisis Management](https://crisisconsultant.com/), [The Robert F. Kennedy Human Rights Foundation](https://rfkhumanrights.org/), She Change, [Pyxera Global](https://www.pyxeraglobal.org/), [iSOS](https://www.isosgroup.com/), [impakt](https://impaktcorp.com/), [FAIRR](https://www.fairr.org/), [Ethical Markets](https://www.ethicalmarkets.com/), [The CSR Group](https://www.thecsrgroup.com/), and The Global Electronics Council, The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss tools used by leading executives, legal experts, operational practice heads and academics to bring companies into alignment with global standards.",
    url: "https://skytopstrategies.com/conferences/379",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/07/DGJxg-KW0AAswFn.jpeg",
  },
  {
    date: "Event: October 4, 2022 (Stockholm)",
    name:
      " ESG Integration Summit: Framework for Creating a Diverse and Dynamic Marketplace",
    description:
      "Together with sponsors [Morningstar](https://www.morningstar.com/), [EEI](https://www.eei.org/pages/default.aspx), [AIMA](https://www.aima.org/), The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss how companies implement and report practices across the wide horizon of ESG, including select areas such as gender equality, energy, and human rights.",
    url: "https://skytopstrategies.com/conferences/1218",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/08/unnamed-7.png",
  },
  {
    date: "Event: November 9, 2022 (San Francisco)",
    name: "CSR 6.0 Responsibility as a Driver of Company Performance",
    description:
      "Together with sponsors from [Altria](https://www.altria.com/en), [The Robert F. Kennedy Human Rights Foundation](https://rfkhumanrights.org/), and [Canopy](https://canopyplanet.org/), The S-Factor's Founder and CEO, Bonnie-Lyn de Bartok will join this exclusive list of speakers to discuss how companies and CSR practitioners are aligning their initiatives to maximize the benefits of their programs for themselves as well as their stakeholders.",
    url: "https://skytopstrategies.com/conferences/389",
    image:
      "https://skytop-strategies.com/wp-content/uploads/2021/07/THE-ONE-FOR-SANFRAN.jpg",
  },
]

const mediaItems = [
  {
    name: "About S-Factor",

    youtube: "S1s4H7bgx4E",
  },

  {
    date: "October 27, 2021",
    name: "S-Factor wins first place in UBS's Future of Finance Challenge",
    description: `We. Are. Ecstatic! To announce that we have won first place in UBS's Future of Finance Challenge! We would like to take the time to congratulate all of the finalists tuning in from all over the world - [AMPLYFI](https://www.linkedin.com/company/amplyfi/) [3rd-eyes analytics AG](https://www.linkedin.com/company/3rd-eyes/) [Carbonbase](https://www.linkedin.com/company/carbonbase/) [Earthly](https://www.linkedin.com/company/projectearthly/) [Entelligent](https://www.linkedin.com/company/entelligent/) @ValAi Pty Ltd [Vested](https://www.linkedin.com/company/vested-inc/) @Worldflavor [Zeigo](https://www.linkedin.com/company/zeigoenergy/).`,
    url:
      "https://www.linkedin.com/posts/thesfactorco_ubsinnovate-future-finance-activity-6858789572017553408-VWGe/",
    image:
      "https://media-exp1.licdn.com/dms/image/C5622AQGPjqsmfRKqeQ/feedshare-shrink_800/0/1635262863569?e=1649289600&v=beta&t=aSDBVZx2JMg1RE9g5Y_PGfuT40MUkO-7GOofzjdbvGg",
  },
  {
    date: `December 17, 2021`,
    name: `MassChallenge Announces 2022 FinTech Cohort`,
    description: `MassChallenge, the global network for innovators, today announces the 24 startups invited to participate in the 2022 FinTech cohort. MassChallenge accelerates outcomes-driven partnerships between startups and enterprises to fuel innovation`,
    url: `https://masschallenge.org/announcement/mcft22cohort`,
    image: `https://masschallenge.org/files/articles/ft-2022-cohort.png`,
  },
  {
    date: "Feburary, 2022",
    name:
      "We are absolutely thrilled to share with you the release of our Social Alpha Paper today.",
    description: `In an industry first, The Social Factor Data Company, (“S-Factor”) has proven the link between material social performance (the “S” in ESG) and predicting financial outcomes for investment purposes. The release of their paper today, “Inside S-Factor: A Source of Alpha and a Greater Understanding of Materiality”, contains four case studies that test investment strategies designed by The S Factor Co. and validated externally by two quantitative firms.`,
    url: "https://thesfactor.co/analytics/",
    image:
      "https://thesfactor.co/static/materialAlpha-84d5f3eca65dea5ea6b843a0b30a0422.jpg",
  },

  {
    date: "December 24, 2021",
    name: "S-Factor A Year in review: 2021",
    description: `The S Factor Team would like to wish you a very safe and merry holiday! 
    We have summarized our incredible “Year in Review” newsletter in the link below. You can learn more about who we are, the mission we are on, our achievements so far and where we will be headed in 2022! 
    We hope that you will continue to join us on this exciting journey ! 
    `,
    url: "https://conta.cc/3soeuOV",
    image:
      "https://files.constantcontact.com/b1e97c7d701/c15f4f3c-6773-4bc6-bc8e-495d50f731b3.png",
  },
  {
    date: "December 16, 2021",
    name: "The Sustainable Finance Podcast",
    description: `Too often investors focus only on the E and the G in ESG investing: the environmental and governance factors. But what about the S factor, and why should investors and advisors care? Joining us today is Bonnie-Lyn de Bartok, Founder, CEO, Director of The The Social Factor Data Company; The S Factor Co. Co., and she’s going to tell us why, and how #machinelearning and big data can help investors reduce risk and improve performance. Bonnie-Lyn de Bartok has long been a go-to source as a leader in sustainable finance. Listen to her unconventional perspectives on what factors are and are not relevant to investors seeking to generate management `,
    url: "https://lnkd.in/eGqmcmPa",
    image:
      "https://www.brighttalk.com/communication/524680/preview_1639673606.png?width=640&height=360",
  },
  {
    date: "December 18, 2021",
    name:
      "Global supply chain crisis could last another two years, warn experts",
    description: `In 2021 and continuing into this year, conversations about supply chains have been taking place — the evening news, corporate boardrooms, and even holiday shoppers.
    Reporting in The Guardian highlighted that industry experts and economists "believe the problems could persist [for up to 2 years] as the finely calibrated network of world trade, already weakened by months of shipping backlogs, labour shortages and geopolitical tensions, remains “discombobulated”.
    `,
    url:
      "https://www.theguardian.com/business/2021/dec/18/global-supply-chain-crisis-could-last-another-two-years-warn-experts?fbclid=IwAR2GyzsId0Z3AEA7zf4pG7H5DMMSGYHtHK29GVs_YDlclVkeTP3bFWWB12o",
    image:
      "https://i.guim.co.uk/img/media/28f6a5fc9caa11b9ff305a31e3dec2ae38b3cf7a/0_158_3500_2101/master/3500.jpg?width=620&quality=45&auto=format&fit=max&dpr=2&s=1ab07e2152a73eec7e3f3b958b642203",
  },
  {
    date: "September 11, 2021",
    name:
      "Statement of intent to work together towards comprehensive corporate reporting",
    description: `It’s exciting to finally see the public markets defining what #ESG regulation looks like, rather than the companies themselves. 
    Historically, how investors and companies report E,S and G has been scattered with interpretation. The overall confusion has stemmed from a lack of knowledge on how to measure outcomes-based results.
    `,
    url:
      "https://impactmanagementproject.com/structured-network/statement-of-intent-to-work-together-towards-comprehensive-corporate-reporting/?fbclid=IwAR2zcxujRR1TMh312HE9YtMevmAaZ-3_KyrW9Ax4rCm65X2pHk-9bsud11I",
    image:
      "https://29kjwb3armds2g3gi4lq2sx1-wpengine.netdna-ssl.com/wp-content/uploads/Asset-1.png",
  },

  {
    date: "",
    name:
      "The Alternative Data Podcast: Casting an eye on the fastest growing segment in investment",
    description: `Have a listen to the marvellous Q&A session between quantitative legend Herbert Blank and host, Mark Fleming-Williams' on The Alternative Data Podcast.
    At S-Factor we agree with what Herb said about Social Data being the window into the corporate’s soul`,
    url: "https://shows.acast.com/the-alternative-data-podcast",
    image:
      "https://res.cloudinary.com/pippa/image/fetch/h_500,w_500,f_auto/https://assets.pippa.io/shows/5f96e67a0ba69a1929848dde/1616765260682-0d4d68ecc51c5cb7f223c5f8e5bea356.jpeg",
  },
  {
    date: "February 5, 2022",
    name: "I’m often asked, “How does S-Factor use quantitative data?”",
    description: ` Before answering that question, I usually remind people of two things: 
    1.     There are many challenges in finding credible information about companies’ environmental, social, and governance (ESG) practices.
    2.     A lot of the available social factor reporting is qualitative or subjective. You need a consistent method of converting that information into quantitative data.`,
    url:
      "https://www.linkedin.com/posts/bonnielyndebartok_the-elephant-in-the-room-the-esg-contradiction-activity-6893215086907928576-v3rb/",
    image:
      "https://i0.wp.com/blogs.cfainstitute.org/investor/files/2022/01/The-Elephant-in-the-room-Esgs-interrnal-contradiction.png?zoom=2&resize=893%2C546&ssl=1",
  },
  {
    date: "February 26, 2022",
    name:
      "Is it possible that the tide is turning on something that has been visibly obvious for so long?",
    description: `We saw a few trends rise to the top of the ESG space in 2021, with diversity and inclusion beyond gender equality dominating conversations. This is more than the number of women on a company’s board. It takes into account anti-discrimination, freedom of association, collective bargaining, grievance management, ratios of diversity within the labour force, and representation of equity and parity.`,
    url:
      "https://www.linkedin.com/posts/bonnielyndebartok_esg-modernslavery-slavery-activity-6902262514378268672-eKdy",
    image:
      "https://econ.duke.edu/sites/econ.duke.edu/files/styles/news_full_image/public/news-images/7d87cfb0-44e0-42f6-8ee6-5416de9d3f52.sized-1000x1000.jpg?itok=7iTL_ktC",
  },

  {
    date: "October 4, 2021",
    name:
      "PRESS RELEASE: S-FACTOR NAMED ONE OF 43 LEADING TORONTO RISK MANAGEMENT FIRMS AND STARTUPS",
    description: `In September 2021, The S-Factor was named by Daily Finance as one of 43 Leading Ontario Risk Management Firms and Startups. Companies were picked across the size spectrum from cutting edge start-ups to established brands.`,
    url: pr6,
    image:
      "https://df.media/wp-content/uploads/2021/09/Risk-Management--758x505.png",
  },

  {
    date: "September 15, 2021",
    name:
      "PRESS RELEASE: S-FACTOR NAMED ONE OF 97 LEADING ONTARIO RISK MANAGEMENT FIRMS AND STARTUPS",
    description: `In September 2021, The S-Factor was named by Daily Finance as one of 97 Leading Ontario Risk Management Firms and Startups. Companies were picked across the size spectrum from cutting edge start-ups to established brands. They were selected based on exhibiting exceptional performance in any of the following categories: Innovation, Growth, Management and Societal impact.`,
    url: pr7,
    image:
      "https://df.media/wp-content/uploads/2021/09/Risk-Management--758x505.png",
  },

  {
    date: "September 7, 2021",
    name:
      "PRESS RELEASE: S-FACTOR RANKED ON THE GLOBAL AIFINTECH100 BY FINTECH GLOBAL AS ONE OF THE TOP 100 GLOBAL FINTECH INNOVATIORS",
    description: `On September 7, 2021, The S-Factor was named on the Global AIFinTech100 list by FinTech Global as the 1 of the top 100 most innovative companies that are helping to transform the financial services space. The list identifies tech companies that are building AI and machine learning solutions capable of solving challenges or improving efficiency within finance.`,
    url: pr5,
    image:
      "https://fintech.global/aifintech100/wp-content/uploads/2021/06/Image-Home-2.png",
  },

  {
    date: "Aug 23, 2021",
    name:
      "Press Release: Eagle Alpha Spotlight on ESG – Social Data, Featuring S-Factor ",
    description: `On August, 23, 2021 Eagle Alpha produced a spotlight whitepaper titled, “ESG – Social Data” in which The S-Factor Co. is featured. With the introduction of new sustainable finance disclosure regulations and taxonomies from the UN and across the EU, North America, and Asia, it is more important now more than ever to understand ESG data and where investors can source reliable ESG data. To learn more you can download your copy of the report [here](/analytics).<br><br>*About Eagle Alpha*<br>Established in 2012, Eagle Alpha is the pioneer connecting the universe of alternative data. Their solutions are used by buyside firms, private equity firms, corporates, central banks and government to make data-driven investment and business decisions.`,
    currentTab: true,
    downloadReport: true,
    url:
      "https://www.eaglealpha.com/2021/08/23/eagle-alpha-spotlight-social-data/",
    image: socialData,
  },

  {
    date: "August 17, 2021",
    name:
      "Press Release: Blending Alternative Data for ESG Investing, ESG Hackathon Winner",
    description: `On August 17, 2021, Eagle Alpha held a virtual ESG Hackathon in New York. The S Factor Co. was a part of the winning team. Here is a copy of the results in Eagle Alphas whitepaper: "Blending Alternative Data for ESG Investing. The S-Factor is featured for their contributions to a blend of alternative data sets for ESG and the signals and raw data content leading to those signals in tandem with other providers. To learn more about the results of this hackathon you can download your copy of the results [here](/analytics).<br><br>*About Eagle Alpha*<br>Established in 2012, Eagle Alpha is the pioneer connecting the universe of alternative data. Their solutions are used by buyside firms, private equity firms, corporates, central banks and government to make data-driven investment and business decisions.`,
    currentTab: true,
    downloadReport: true,
    url:
      "https://www.eaglealpha.com/2021/08/17/blending-alternative-data-for-esg-investing/",
    image: blendingAlt,
  },
  {
    date: "August 7, 2021",
    name: "Press Release: NLP & AI for ESG Analysis, Featuring S-Factor",
    description: `On September 7, 2021 Eagle Alpha produced a spotlight whitepaper called, “NLP & AI For ESG Analysis” in which the The S-Factor Co. is featured. In the ESG landscape, some of the pain points for asset managers, are a lack of comparable, high-quality, high-frequency raw data, a lack of standardized definitions of sustainable activities, conflicting ESG taxonomies, and divergence in scoring, and methodologies across traditional ESG data vendors. The S-Factor proves to solves some of the industry’s biggest issues. You can download your copy of the results [here](/analytics)<br><br>*About Eagle Alpha*<br>Established in 2012, Eagle Alpha is the pioneer connecting the universe of alternative data. Their solutions are used by buyside firms, private equity firms, corporates, central banks and government to make data-driven investment and business decisions.`,
    url:
      "https://www.eaglealpha.com/2021/09/07/eagle-alpha-spotlight-nlp-ai-esg-analysis/",
    image: aiNlp,
  },
  {
    date: "August 15, 2021",
    name:
      "PRESS RELEASE: S-FACTOR SELECTED AS ONE OF THE 40 FINALISTS FROM OVER 400 FINTECHS FOR THE FUTURE OF FINANCE CHALLENGE 2021",
    description: `n August 2021, The S-Factor was named as a finalist for UBS’s Future of Finance Challenge 2021. They were one of the 40 finalists selected from a pool of over 400 Fintechs and will pitch in the finals in October.`,
    url: pr4,
    image:
      "https://www.ubs.com/content/homepage/global-all/en/new-promotions/q32021/_jcr_content/promotion/image-xs.680.png/1635235503482.png",
  },
  {
    date: "August 6, 2021",
    name: "Quantifying the real alpha found via social change",
    description:
      "Bonnie-Lyn de Bartok has seen the impact of social-focused investing with her own eyes and these days is spreading the word of a new source of alpha as the founder and CEO of The S Factor Co.",
    url:
      "https://www.alternativeswatch.com/2021/08/06/quantifying-the-real-alpha-found-via-social-change/",
    image:
      "https://www.alternativeswatch.com/wp-content/uploads/2021/08/Bonnie-Lyn-de-Bartok.jpg",
  },

  {
    date: "July 21, 2021",
    name:
      "Fintech TV - The Social Factor, beyond IRR – The S Factor in Humanity",
    description:
      "Bonnie Lyn De Bartok, Founder & CEO at S-Factor, joins our host, Jeff Gitterman, on TheIMPACT to discuss the importance of sustainability, vis-a-vis the “S”, in ESG/SDG as she uses her 25+ years of experience to measure “saving the world” beyond “one project at a time.”",
    url: "https://fintech.tv/the-s-factor-in-humanity-esg/?list",
    image:
      "https://static.fintech.tv/wp-content/uploads/2020/07/17100128/vlcsnap-2020-07-21-23h03m36s188.jpg",
  },

  {
    date: "July 15, 2021",
    name:
      "PRESS RELEASE: S-FACTOR NAMED ONE OF THE TOP 14 SOCIAL IMPACT STARTUPS AND COMPANIES IN CANADA",
    description: `In July 2021, The S-Factor was named one of one of the top 14 social impact start-ups and companies in Canada by Best Startup Canada. Startups and companies named are taking a variety of approaches to innovating the Social Impact industry. Companies were picked across the size spectrum from cutting edge start-ups to established brands.`,
    url: pr3,
    image:
      "https://beststartup.ca/wp-content/uploads/2021/06/BestStartup-Canada.png",
  },

  {
    date: "June 29, 2021",
    name:
      "Press Release: Alternatives Watch releases Finding ‘S’ in ESG report",
    description: `On June 29, 2021 Alternatives Watch produced a special report called, "Finding the S in ESG" in which The S-Factor Co. is featured. The report, which sought the opinions of a broad range of investors to get an idea of how far the discussion of social justice has come in the world of institutional investing. Download your copy of the report [here](/analytics).<br> <br>*About Alternatives Watch*<br> Alternatives Watch is a leading online news service dedicated to institutional investment in alternative investments including hedge funds, private equity, private credit, real estate, infrastructure, and CTAs/managed futures.`,
    currentTab: true,
    downloadReport: true,
    url:
      "https://www.alternativeswatch.com/2021/06/29/alternatives-watch-releases-finding-the-s-in-esg-report/",
    image: findingTheS,
  },

  {
    date: "June 25, 2021",
    name:
      "PRESS RELEASE: S-FACTOR NAMED ONE OF THE 36 MOST INNOVATIVE TORONTO BASED PREDITIVE ANALYTICS COMPANIES BY FUTUROLOGY",
    description: `On June 25, 2021, The S-Factor was named by Futurology as one of the 36 most innovative Toronto based companies. Companies were picked across the size spectrum from cutting edge start-ups to established brands. They were selected based on exhibiting exceptional performance in any of the following categories: Innovation, Growth, Management and Societal impact`,
    url: pr2,
    image:
      "https://i0.wp.com/futurology.life/wp-content/uploads/2021/07/Predictive-Anlaytics.png?resize=758%2C426&ssl=1",
  },

  {
    date: "November 18, 2020",
    name:
      "Investment Week, IW Long Reads: The industry’s roadmap to measuring the “S” in ESG",
    description:
      "The industry has widely accepted that this year, dominated by the devastating effects of the Covid-19 pandemic and rising awareness of inequality amid the Black Lives Matter protests in the US, has highlighted the importance of the 'S' in ESG investing. ",
    url:
      "https://www.investmentweek.co.uk/analysis/4022852/the-sustainability-roadmap-industry-s-path-to-measuring-the-s-in-esg",
    image:
      "https://www.investmentweek.co.uk/api/v1/wps/7dbb933/9cf5de9e-6220-4e4a-93c9-5ee41d96d9e5/10/csr-boardroom-diversity-esg-0618-030-1920x1282.jpg",
  },

  {
    date: "October 27, 2020",
    name: "Morningstar, It about more than Lobster",
    description:
      "If there’s one thing that 2020 has taught us, it’s that environmental, social and governance (also called ESG) issues are now at the forefront of public discourse.",
    url:
      "https://www.morningstar.ca/ca/news/206664/its-about-more-than-lobster.aspx",
    image:
      "https://www.morningstar.co.uk/static/UploadManager/Assets/Lobster%20on%20plate.png",
  },

  {
    date: "October 26, 2020",
    name: "Euromoney: Can the year of social turn talk into action?",
    description:
      "Show more sharing options The Covid pandemic and racial injustice protests have thrust social investing into the spotlight this year. However, using this to achieve long-term change on the ground will be a tough job.",
    url:
      "https://www.euromoney.com/article/27o4seab7p5ht44t75am8/esg/can-the-year-of-social-turn-talk-into-action",
    image:
      "https://assets.euromoneydigital.com/dims4/default/6e04e2c/2147483647/strip/true/crop/960x535+0+0/resize/800x446!/quality/90/?url=http%3A%2F%2Feuromoney-brightspot.s3.amazonaws.com%2F1b%2Fcf%2F5c5b76d04b178ca9d06349dea065%2Fblm-crowd-960.jpg",
  },

  {
    date: "September 22, 2020 ",
    name: "Morningstar: What can we learn from the Rio Tinto disaster?",
    description:
      "The mining company intentionally destroyed a 46,000-year old sacred site for US$ 135 million worth of iron ore – and it could cost them billions.",
    url:
      "https://www.morningstar.ca/ca/news/205544/what-can-we-learn-from-the-rio-tinto-disaster.aspx",
    image:
      "https://www.morningstar.co.uk/static/UploadManager/Assets/Rio%20Tinto%20building.png",
  },

  {
    date: "August 17, 2020",
    name: "Wealth Professional : Bridging the Social Data Gap ",
    description:
      "Information is a powerful thing, but when one focuses too much on individual data points and draws no trend lines between them, even the most powerful signal can remain unheard. That’s the problem Bonnie Lyn de Bartok saw more than 10 years ago when it came to social factors in ESG.",
    url:
      "https://www.wealthprofessional.ca/investments/socially-responsible-investing/bridging-the-social-gap-in-esg-investing/332420",
    image:
      "https://cdn-res.keymedia.com/cms/images/ca/155/0299_637332521503967314.jpg",
  },

  {
    date: "Aug 13, 2020",
    name:
      "BNN Bloomberg –  Companies with 'very strong social management' have been most resilient during COVID-19: S-Factor CEO",
    description:
      "Bonnie Lyn de Bartok, founder and CEO of the S-Factor, a company that has automated social implications in investments, joined BNN Bloomberg to discuss why factoring the “S” in ESG investing is necessary.",
    url:
      "https://www.bnnbloomberg.ca/investing/video/companies-with-very-strong-social-management-have-been-most-resilient-during-covid-19-s-factor-ceo~2013538",
    image:
      "https://images2.9c9media.com/image_asset/2020_8_13_17b7e7c0-5ad4-4beb-b336-2dddd8cca6d5_jpg_743x410.jpg?width=640&maintain_aspect=1",
  },
  {
    date: "August 10, 2020",
    name: "Social Factors getting their turn in the ESG spotlight",
    description:
      "Founder and CEO of The S FactorCo. explains how best-in-class companies have stood out amid pandemic-induced focus on social issues",
    url:
      "https://www.wealthprofessional.ca/investments/socially-responsible-investing/social-factors-getting-their-turn-in-the-esg-spotlight/332219",
    image:
      "https://cdn-res.keymedia.com/cms/images/ca/155/0377_637326482391570855.jpg",
  },
  {
    date: "August 7, 2020",
    name:
      "CAASA : Annual Private Family Office Summit, S-Factor, Alquity Impact Fund, Waratagh , and Bodhi Research",
    description:
      "Open dialog and varying perspectives on ESG approaches and integration. ",
    url: "https://caasa.ca/event/caasa-family-office-summit-2020/",
    image:
      "https://caasa.ca/wp-content/uploads/2020/09/Moca-19-6110-500x500.jpg",
  },

  {
    date: "July 29, 2020",
    name: "CAASA : Podcast, SASB  & S-Factor ",
    description:
      "The Sustainability Accounting Standards Board. The SASB ESG materiality map is fully incorporated in the S-Factor product, amongst other frameworks. Additionally, we are helping to advise on SASBs continued development marrying the Human Capital dimension with materiality and performance.",
    url:
      "https://podcasts.apple.com/ca/podcast/the-s-factor-co-sasb/id1505533220?i=1000485921654",
    image:
      "https://is2-ssl.mzstatic.com/image/thumb/Podcasts125/v4/14/9a/87/149a877d-a95a-641c-abf8-ab76cac72418/mza_5184967311342045730.jpg/313x0w.webp",
  },

  {
    date: "June 23, 2020",
    name:
      "TheStreet: Amazon Starts $2 Billion VC Fund to Combat Climate Change",
    description:
      "S-Factor CEO weighs in on the launch of Amazon’s VC fund to combat climate change. She urges investors to place the same importance on social and human rights issues. ",
    url:
      "https://www.thestreet.com/investing/amazon-amzn-investment-fund-carbon-footprint-jeff-bezos",
    image: "https://assets-jpcust.jwpsrv.com/thumbnails/bynwtn6h-720.jpg",
  },
  {
    date: "June 16, 2020",
    name: "TheStreet: Tesla to Buy Cobalt for Its Batteries From Glencore",
    description:
      "S-Factor CEO weighs in on Tesla’s supply chain decision to buy cobalt for its batteries from Glencore. ",
    url:
      "https://www.thestreet.com/investing/tesla-glencore-cobalt-batteries-elon-musk",
    image: "https://assets-jpcust.jwpsrv.com/thumbnails/uys51dv1-720.jpg",
  },

  // {
  //   date: "May 20, 2021",
  //   name: "Wall Street Green Summit, The S in ESG – S- Factor CEO",
  //   description:
  //     "The Social Factors in ESG. Everything you need to know as an investor or a company in 2021.",
  //   url:
  //     "https://www.linkedin.com/in/bonnielyndebartok/detail/treasury/position:1421657872/?entityUrn=urn%3Ali%3Afsd_profileTreasuryMedia%3A(ACoAAACgaUkBn74zILjf8ukrcmdIYq_skoSu6v4%2C1635469145021)&amp;section=position%3A1421657872&amp;treasuryCount=4",
  //   image:
  //     "https://media-exp1.licdn.com/dms/image/sync/C5627AQGIlUp85M1WxA/articleshare-shrink_1280_800/0/1631555647901?e=1632171600&v=beta&t=jiSKBb0mlP_9JbO4Vsn-J9AVCfzNL4-VqWWdovythDM",
  // },
  {
    date: "May 8, 2020",
    name: "TEDx – The Social Impact of Math, Bonnie Lyn de Bartok",
    description:
      "Can mathematics, big data and AI help evaluate the socialimpact of an organization's decisions? Bonnie Lyn De Bartok believes they can be used to develop a robust and quantifiable metric to do just that.",
    url: "https://www.youtube.com/watch?v=qQSV5xW4SyI",
    image: "https://img.youtube.com/vi/qQSV5xW4SyI/0.jpg",
  },

  {
    date: "April 20, 2020",
    name:
      "Alternatives Watch: Despite pandemic, more alts firms seek data to invest for the greater good.",
    description:
      "The S Factor Co., and CEO, Bonnie Lyn de Bartok, “found that its newly created S-Factor Pandemic Score signals seem to be five to 10 days ahead of the actual stock performance moves.”",
    url:
      "https://www.alternativeswatch.com/2020/04/20/despite-pandemic-more-alts-firms-seek-data-to-invest-for-the-greater-good/",
    image:
      "https://www.alternativeswatch.com/wp-content/uploads/2020/04/Despite-pandemic-more-alts-firms-seek-data-to-invest-for-the-greater-good.jpg",
  },

  {
    date: "April 6, 2020",
    name: "Medium/Nossa Capital",
    description:
      "Nossa Capital shouts out the S Factor for our unique coronavirus dataset and special report. ",
    url:
      "https://medium.com/nossa-capital/200-000-march-tweets-scraped-to-understand-public-sentiment-to-esg-be32f38af79f",
    image: "https://miro.medium.com/max/1400/0*fZ5xT0UIJXXJmq1V",
  },
  {
    date: "April 1, 2020",
    name: "BrightTALK: Covid-19 Social Data - How are companies managing?",
    description:
      "Pandemic social data and trends. Learn about which companies are managing the pandemic well, and which companies are profiteering by selling fear. How are companies meeting regulatory requirements? What impacts are they making? What is public sentiment saying about them? How does it affect their impacts and ratings?",
    url: "https://www.brighttalk.com/webcast/1570/391759",
    image:
      "https://www.brighttalk.com/communication/391759/preview_1585585449.png",
  },

  // {
  //   date: "August 20, 2020",
  //   name: "ESG Roundtable with Goldman Sachs, S-Factor, UST Global ",
  //   description:
  //     "Industry experts and data providers will be discussing progressive and differentiated topics surrounding ESG.",
  //   url: "https://eaglealpha.com/resource-webinar-esg-roundtable-aug-2020/",
  //   image: "",
  // },

  // {
  //   date: "August 13, 2020",
  //   name:
  //     "BNN : Bloomberg Companies with very strong social management have been most resilient during COVID-19: S-Factor CEO",
  //   description:
  //     "Bonnie Lyn de Bartok, founder and CEO of the S-Factor, a company that has automated social implications in investments, joined BNN Bloomberg to discuss why factoring the “S” in ESG investing is necessary. She claims that considering socio-cultural and socio-political implications can save all parties time and money.",
  //   url:
  //     "https://www.bnnbloomberg.ca/video/companies-with-very-strong-social-management-have-been-most-resilient-during-covid-19-s-factor-ceo~2013538",
  //   image: "",
  // },

  // {
  //   date: "July 24, 2020",
  //   name: "BNN : FINTECH. TV : The S-Factor in Humanity Beyond IRR",
  //   description:
  //     "Today, S-Factor has evolved into a subject matter firm for artificial intelligence (AI), big data, and deep technology to understand behaviors, outcomes and financial correlations, thereof. The team at S-Factor tackles problems such as a community impact for mining projects, measuring jobs creation and how government programs can enhance or not through a real net-benefit standards of measurement.",
  //   url: "https://fintech.tv/the-s-factor-in-humanity-esg/",
  //   image: "",
  // },
  // {
  //   date: "June XX, ",
  //   name: "CAASA Podcast: S-Factor & Fundata",
  //   description:
  //     "A moderated session between Canadian Fundata and The S-Factor Co. on the topic of ESG and the future of Fund Analytics.",
  //   url:
  //     "https://podcasts.apple.com/ca/podcast/the-s-factor-co-fundata/id1505533220?i=1000485921655",
  // },
  // {
  //   date: "June 23rd, 2020",
  //   name: "Measuring Human Capital Management, the Quebec SASB FSA",
  //   description:
  //     "Webinar on the topic of Human Capital Management and its Financial Effects. A topic which has gained more attention in recent years, and even more so with the current coronavirus crisis.",
  //   url:
  //     "https://us02web.zoom.us/webinar/register/WN_DJvzN_PzTHKPzcdSGfucTQ?t=1592331230927",
  // },

  // {
  //   date: "May 22nd, 2020",
  //   name: "Eagle Alpha: Virtual Data Conference",
  //   description:
  //     "S-Factor pitched their Alpha Capture outcomes with their innovative pandemic tracking dataset. ",
  //   url: "https://eaglealpha.com/virtual-data-conference/",
  //   image: "",
  // },

  // {
  //   date: "April 15th, 2020",
  //   name: "CAASA Podcast: S-Factor & Alquity: Both Sides of the Coin",
  //   description:
  //     "In this episode led by James Burron, Co-Founder CAASA, joined by Robert Lampl Head of Emerging Markets for Alquity Investment Management, an emerging markets portfolio manager and S-Factor, a big data insight provider who both have ESG as the core component of their business (with S-Factor™ focusing on the Social aspects). We take a deep dive look into what ESG truly is, how it can be a leading indicator of equity performance, and the inherent risks that come from so-called ESG-washing.",
  //   url:
  //     "https://podcasts.apple.com/ca/podcast/s-factor-alquity-investment-management/id1505533220?i=1000471707221",
  //   image: "",
  //   },

  {
    date: "March 18th, 2020",
    name: "BrightTALK Webinar: The Impact of Math: The Social Dimension",
    description:
      "The online session titled The Impact of Math: The Social Dimension is a deep-dive into the Social Factors™ or the “S in ESG™” and its power of influence and performance in the realm of ESG considerations.",
    url:
      "https://www.brighttalk.com/webcast/1570/390485?utm_source=brighttalk-portal&utm_medium=web&utm_content=bonnie%20lyn&utm_campaign=webcasts-search-results-feed#/register",
    image:
      "https://www.brighttalk.com/communication/390485/preview_1583944582.png",
  },

  {
    date: "Mar 11, 2020",
    name: "BrightTALK – the Social Impact of Math Webinar – S-Factor",
    description:
      "Bonnie Lyn recently presented a TEDx Talk at the Fields Institute for Mathematical Sciences at the University of Toronto where she discussed The Impact of Math.",
    url: "https://www.brighttalk.com/webinar/the-impact-of-math/",
    image:
      "https://www.brighttalk.com/wp-content/uploads/2019/08/AdobeStock_281210365.jpeg",
  },
  // {
  //   date: "February 13th, 2020",
  //   name: "TEDx UofT : Dimensions, The Impact of Math",
  //   description:
  //     'Bonnie Lyn de Bartok gave a TEDx talk at the Fields Institute (CQAM), for Research in Mathematical Sciences. At the salon, themed "Dimensions," she introduced the S-Factor quantitative model. ',
  //   url:
  //     "https://www.ted.com/talks/bonnie_lyn_de_bartok_the_social_impact_of_math",
  //   image: "",
  //   },
  {
    date: "February 6th, 2020",
    name: "Private Asset Management Awards (NYC)",
    description:
      'Often regarded as the "Oscars" of the US private asset management industry, the highly sought after PAM Awards for over two decades have provided a platform for top investment professionals, wealth advisors, legal firms, consultants and other key service providers operating within the sector, to showcase the incredible achievements they have accomplished. S-Factor wins “ESG Advocate of the Year.”',
    url: "https://pamawards.awardstage.com/#About",
    image:
      "https://downloads.awardstage.com/themes/logos/1631895626-G7YpNVPo0qYUAWGUVAVV95L95.png",
  },
  {
    date: "January 16th, 2020",
    name: "Eagle Alpha Alternative Data Conference (NYC)",
    description:
      "World's First Alternative and External Data Conference for Buyside Funds, Private Equity Firms and Corporates. This year’s event was attended by a record 800+ buyside and 100+ data vendors.",
    url: "https://eaglealpha.com/nyc_2020/",
    image:
      "https://downloads.awardstage.com/themes/logos/1631895626-G7YpNVPo0qYUAWGUVAVV95L95.png",
  },
  {
    date: "January 9-12th, 2020",
    name:
      "WORLD FORUM ON FINANCE, TECHNOLOGY, INVESTMENTS & RISK MANAGEMENT at the MMF Symposium 2020 (Blue Mountain)",
    description:
      "This year’s MMF Symposium addressed advances in financial innovation paying special attention to the start-ups that are shaping our immediate future.",
    url: "https://symposium.mmf.utoronto.ca/",
    image:
      "https://symposium.mmf.utoronto.ca/images/wes1498ex-191635-Exterior4.jpg",
  },
  {
    date: "November 22, 2018",
    name:
      "PRESS RELEASE: S-FACTOR CEO, BONNIE-LYN DE BARTOK NAMED FINALIST AS FEMALE EXUCUTIVE OF THE YEAR BY WP",
    description: `On November 22, 2018, S-Factor CEO, Bonnie-Lyn de Bartok was named as a finalist for Female Executive of the Year by WP. WP’s inaugural Women in Wealth Management conference was a rip-roaring success with thought-provoking speakers and the unveiling of its first award winners.`,
    url: pr1,
    image:
      "https://ca.res.keymedia.com/files/image/iStock-678500348-talkk-business-womann-laptopp-meett-clientt-happyy-agentt.jpg",
  },
]

const newsItems = [
  {
    name: "S-Factor January 2022 Newsletter",
    url:
      "https://myemail.constantcontact.com/Takeaways-from-January.html?soid=1133196582427&aid=iof6vGZ1CWM",
    id: "January2022",
  },
  {
    name: "S-Factor October 2020 Newsletter",
    pdf: OctPdf,
    id: "October2020",
  },
  {
    name: "S-Factor Aug-Sep 2020 Newsletter",
    pdf: AugSeptPdf,
    id: "AugSept2020",
  },
  {
    name: "S-Factor July 2020 Newsletter",
    pdf: julyPdf,
    id: "July2020",
  },
  {
    name: "S-Factor June 2020 Newsletter",
    pdf: junePdf,
    id: "June2020",
  },
  {
    name: "S-Factor May 2020 Newsletter",
    pdf: mayPdf,
    id: "May2020",
  },
  {
    name: "S-Factor April 2020 Newsletter",
    pdf: aprilPdf,
    id: "April2020",
  },
  {
    name: "S-Factor March 2020 Newsletter",
    pdf: marchPdf,
    id: "March2020",
  },
  {
    name: "S-Factor February 2020 Newsletter",
    pdf: febPdf,
    id: "Feb2020",
  },
  {
    name: "S-Factor January 2020 Newsletter",
    pdf: janPdf,
    id: "Jan2020",
  },
  {
    name: "S-Factor December 2019 Newsletter",
    pdf: decemberPdf,
    id: "Dec2019",
  },
]

const additionalIndustryNews = [
  {
    website_url:
      "https://www.responsible-investor.com/articles/nominee-shareholders-have-responsibilities-for-human-rights-impacts-caused-by-companies-owned-on-behalf-of-clients-says-un",
    title:
      "Responsible Investor: Banks responsible for human rights impacts of companies owned on behalf of clients, says UN",
  },
  {
    website_url:
      "https://www.responsible-investor.com/articles/could-social-impact-bonds-make-a-comeback-as-part-of-the-covid-recovery",
    title:
      "Responsible Investor: Could social impact bonds make a comeback as part of the COVID recovery?",
  },
  {
    website_url:
      "https://www.reuters.com/business/sustainable-business/sustainable-bond-issuance-reaches-record-high-first-quarter-2021-04-15/",
    title:
      "Reuters: Sustainable bond issuance reaches record high first quarter 2021",
  },
  {
    website_url:
      "https://www.hrw.org/news/2021/06/11/germany-new-supply-chain-law-step-right-direction",
    title:
      "Human Rights Watch: Germany: New supply chain law step right direction",
  },
  {
    website_url:
      "https://pressroom.ifc.org/all/pages/PressDetail.aspx?ID=26366&mkt_tok=Njg1LUtCTC03NjUAAAF9ZxLTjUt5UPJiGIL0ghLxfR-Ncv7PUyiVH0HtPXqAQJYhYDm1kMuNV-uLN0Jn_MeqJmFkbzLErOSYFa1uXR2KuDcGhn2_lTH1GjgDgZr5FoxX",
    title: "IFC: The impact of the COVID-19 pandemic on the supply chain",
  },
  {
    website_url:
      "https://www.esginvestor.net/social-gaps-persist-in-crowded-esg-data-market/",
    title: "ESG Investor: Social gaps persist in crowded ESG data market",
  },
  {
    website_url:
      "https://capitalmonitor.ai/factor/environmental/investment-experts-urge-tracking-error-rethink-for-net-zero/",
    title:
      "Capital Monitor: Environmental investment experts urge tracking error, rethink for net zero",
  },
  {
    website_url:
      "https://www-barrons-com.cdn.ampproject.org/c/s/www.barrons.com/amp/articles/modern-portfolio-theory-ignores-crucial-systemic-risk-author-says-51631962802",
    title:
      "Barrons: Modern portfolio theory ignores crucial systemic risk, author says",
  },
  {
    website_url:
      "https://www.nasdaq.com/articles/advancing-your-esg-reporting-strategy",
    title: "NASDAQ: Advancing your ESG reporting strategy",
  },
  {
    website_url:
      "https://scholarship.law.upenn.edu/cgi/viewcontent.cgi?article=2024&context=jil",
    title:
      "Scholarship: The impact of the COVID-19 pandemic on the supply chain",
  },
  {
    website_url:
      "https://www.pioneerspost.com/business-school/20210906/snapshot-of-uk-social-investment-history-and-where-we-go-here?utm_source=PPnewsletter&utm_medium=email&utm_campaign=324",
    title:
      "Pioneers Post: Snapshot of UK social investment history and where we go here",
  },
  {
    website_url:
      "https://www.aqr.com/Insights/Perspectives/Shorting-Your-Way-to-a-Greener-Tomorrow",
    title: "AQR: Shorting your way to a greener tomorrow",
  },
  {
    website_url:
      "https://www.cnn.com/2021/09/15/world/climate-pledges-insufficient-cat-intl/index.html",
    title: "CNN: Climate pledges insufficient to address COVID-19",
  },
  {
    website_url:
      "https://www.responsible-investor.com/articles/investment-motivations-are-being-ignored-in-esg",
    title:
      "Responsible Investor: Investment motivations are being ignored in ESG",
  },
  {
    website_url:
      "https://www.ft.com/content/0eb64160-9e41-44b6-8550-742a6a4b1022",
    title: "FT: The impact of the COVID-19 pandemic on the supply chain",
  },
  {
    website_url:
      "https://www.responsible-investor.com/articles/could-social-impact-bonds-make-a-comeback-as-part-of-the-covid-recovery",
    title:
      "Responsible Investor: Could social impact bonds make a comeback as part of the COVID recovery?",
  },
  {
    website_url: "https://www.greenbiz.com/article/inside-war-esg-talent",
    title: "Greenbiz: Inside the war on ESG talent",
  },
  {
    website_url:
      "https://www.forbes.com/sites/bobeccles/2021/07/27/sec-commissioner-hester-m-peirces-chocolate-covered-ideological-screed/?sh=60123ccf47be",
    title:
      "Forbes: Sec Commissioner Hester M. Peirces: Chocolate-covered Ideological Screed",
  },
  {
    website_url: "https://www.unpri.org/policy/a-legal-framework-for-impact",
    title: "UNPRI: A legal framework for impact",
  },
  {
    website_url:
      "https://www.investmentnews.com/blackrock-voted-against-255-directors-because-of-climate-issues-209078",
    title:
      "Investment News: Blackrock Voted Against 255 Directors Because of Climate Issues",
  },
  {
    website_url:
      "https://www.responsible-investor.com/articles/annual-reports-in-china-will-now-include-environmental-and-social-information",
    title:
      "Responsible Investor: Annual reports in China will now include environmental and social information",
  },
]

class NewsPage extends Component {
  state = {
    externalArticles: [],
    externalCanLoadMore: false,
    externalPage: 1,
    internalArticles: [],
    internalCanLoadMore: false,
    internalPage: 1,
    showingAllNewsletters: false,
    modalIsOpen: false,
    activeNewsletter: null,
    name: "",
    email: "",
    company: "",
    position: "",
    errors: [],
    windowWidth: null,
    isDesktop: true,
  }

  feedService = null

  componentWillMount() {
    this.feedService = new FeedService()
    this.fetchArticles(["internal"])
  }

  componentDidMount = () => {
    if (typeof window !== undefined) {
      this.setState({ windowWidth: window.innerWidth })
      if (this.state.windowWidth >= 700) {
        this.setState({ isDesktop: true })
      } else {
        this.setState({ isDesktop: false })
      }
      window.addEventListener("resize", this.onResize)
    }
    setTimeout(() => {
      this.onResize()
    }, 100)
  }

  componentWillUnmount = () => {
    if (typeof window !== undefined) {
      window.removeEventListener("resize", this.onResize)
    }
  }

  onResize = () => {
    console.log("RESIZE")
    if (typeof window !== undefined) {
      console.log("RESIZE 1")
      this.setState({ windowWidth: window.innerWidth })
      console.log(window.innerWidth)
      if (window.innerWidth >= 700) {
        this.setState({ isDesktop: true })
      } else {
        this.setState({ isDesktop: false })
      }
    }
  }

  fetchArticles(kind = ["external", "internal"]) {
    if (kind.includes("external")) {
      this.feedService
        .fetch("external", this.state.externalPage, 50)
        .then(data => {
          this.setState({
            externalArticles: [...this.state.externalArticles, ...data.results],
            externalCanLoadMore: data.next,
          })
        })
    }
    if (kind.includes("internal")) {
      this.feedService
        .fetch("internal", this.state.internalPage, 32)
        .then(data => {
          this.setState({
            internalArticles: [...this.state.internalArticles, ...data.results],
            internalCanLoadMore: data.next,
          })
        })
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  externalLoadMore() {
    this.setState({ externalPage: this.state.externalPage + 1 })
    this.fetchArticles(["external"])
  }

  internalLoadMore() {
    this.setState({ internalPage: this.state.internalPage + 1 })
    setTimeout(() => {
      this.fetchArticles(["internal"])
    }, 10)
  }

  handleNewsletterClick(newsletter) {
    this.setState({ modalIsOpen: true, activeNewsletter: newsletter })
  }

  afterOpenModal() {}

  closeModal() {
    this.setState({ modalIsOpen: false, activeNewsletter: null })
  }

  handleFormSubmit(newsletter) {
    this.setState({ errors: [] })

    const { email, name, company, position } = this.state

    const errors = []
    if (!email) {
      errors.push("email")
    } else if (!this.validateEmail(email)) {
      errors.push("email")
    }

    if (!name) {
      errors.push("name")
    }
    if (!company) {
      errors.push("company")
    }
    if (!position) {
      errors.push("position")
    }
    if (errors.length) {
      this.setState({ errors })
      return
    }

    const url = `https://us-central1-sfactor-website.cloudfunctions.net/sendReport?reportId=newsletter&newsletterId=${newsletter.id}&email=${email}&name=${name}&company=${company}&position=${position}`
    fetch(url)
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log("data", data)
        alert(
          `Please check your email for instructions on how to download ${newsletter.name}.`
        )
        this.closeModal()
      })
      .catch(() => {
        alert("A problem occurred. Please double check the fields.")
      })
  }

  renderMediaItems() {
    return mediaItems.map(item => {
      if (item.youtube) {
        return (
          <li>
            <VideoEmbed youtubeId={item.youtube} />
          </li>
        )
      }
      return (
        <li>
          <a href={item.url} target="_blank">
            <i class="fa fa-external-link "></i>{" "}
            {item.name.slice(0, 64) + (item.name.length > 64 ? "..." : "")}
            <span>{item.date}</span>
            <span>
              {item.description.slice(0, 140) +
                (item.description.length > 140 ? "..." : "")}
            </span>
          </a>
        </li>
      )
    })
  }

  renderNewsItems() {
    return newsItems.map((row, index) => {
      const hasBorder = index + 1 != newsItems.length
      return (
        <div className="careerRow">
          <div
            style={{
              position: "relative",
              paddingTop: 18,
              paddingBottom: 18,
              fontSize: 16,
              color: "#fff",
            }}
          >
            {row.name}
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              {row.pdf ? (
                <CtaButton
                  onClick={() => this.handleNewsletterClick(row)}
                  dark={true}
                />
              ) : (
                <CtaButton dark={true} href={row.url} target={"_blank"} />
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 1,
              backgroundColor: hasBorder ? "#ccc" : "transparent",
            }}
          />
        </div>
      )
    })
  }

  renderFeed(articles, columns = 3, dark = false, renderTop = true) {
    const cols = chunkArray(articles, columns)

    let colSize = "third"
    if (columns === 1) {
      colSize = "whole"
    } else if (columns === 2) {
      colSize = "half"
    }

    return cols.map(articles => {
      return (
        <Col size={colSize} style={{ paddingRight: 8 }}>
          {articles.map(article => {
            return (
              <div className="feed-items">
                <FeedItem
                  article={article}
                  dark={dark}
                  renderTop={renderTop}
                ></FeedItem>
              </div>
            )
          })}
        </Col>
      )
    })
  }

  renderNews(articles, columns = 3, dark = false) {
    const cols = chunkArray(articles, columns)

    let colSize = "third"
    if (columns === 1) {
      colSize = "whole"
    } else if (columns === 2) {
      colSize = "half"
    }

    return cols.map(articles => {
      return (
        <Col size={colSize} style={{ paddingRight: 8 }}>
          {articles.map(article => {
            if (article.youtube) {
              return (
                <div>
                  <VideoEmbed youtubeId={article.youtube} />
                  <div style={{ height: 12 + "px" }}></div>
                </div>
              )
            }
            return (
              <div className="feed-items">
                <NewsItem article={article} dark={dark}></NewsItem>
              </div>
            )
          })}
        </Col>
      )
    })
  }

  renderInternalFeed() {
    if (!this.state.internalArticles || !this.state.internalArticles.length) {
      return null
    }

    var articles = this.state.internalArticles.filter(a =>
      a.title.includes(":")
    )
    articles = [...additionalIndustryNews, ...articles]

    // return this.renderFeed(articles, 1, true)
    // return this.renderFeed(articles, 1, true, false)

    return articles.map(article => {
      return (
        <div className="careerRow">
          <div
            style={{
              position: "relative",
              paddingTop: 18,
              paddingBottom: 18,
              paddingRight: 30,
              fontSize: 14,
              color: "#fff",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {article.title}
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <CtaButton
                dark={true}
                href={article.website_url}
                target="_blank"
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "#ccc",
            }}
          />
        </div>
      )
    })
  }

  renderExternalFeed() {
    if (!this.state.externalArticles || !this.state.externalArticles.length) {
      return null
    }

    const articles = this.state.externalArticles

    return articles.map(article => {
      return (
        <div className="careerRow">
          <div
            style={{
              position: "relative",
              paddingTop: 18,
              paddingBottom: 18,
              paddingRight: 30,
              fontSize: 16,
              color: "#fff",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {article.title}
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <CtaButton dark={true} />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "#ccc",
            }}
          />
        </div>
      )
    })

    return this.renderFeed(articles, 1, true)
  }

  renderInternalLoadMoreButton() {
    if (!this.state.internalCanLoadMore) {
      return null
    }
    return (
      <div className="text-center">
        <button
          className="load-more-btn"
          onClick={this.internalLoadMore.bind(this)}
        >
          Load More
        </button>
      </div>
    )
  }

  renderExternalLoadMoreButton() {
    if (!this.state.externalCanLoadMore) {
      return null
    }
    return (
      <div className="text-center">
        <button
          className="load-more-btn"
          onClick={this.externalLoadMore.bind(this)}
        >
          Load More
        </button>
      </div>
    )
  }

  renderModalContent() {
    if (!this.state.activeNewsletter) {
      return
    }

    const newsletter = this.state.activeNewsletter

    return (
      <>
        <Group>
          <Col size="whole">
            <h3>Download {newsletter.title}</h3>
            <div className="headingAccent"></div>
            <hr />
            <p>
              Please provide the following info to download {newsletter.name}.
            </p>
            <p></p>
          </Col>
        </Group>
        <Group>
          <Col size="half">
            <div className="form-group">
              <label>Name</label>
              <input
                tabIndex={1}
                type="text"
                className={`form-control ${
                  this.state.errors.includes("name") ? "error" : ""
                }`}
                placeholder="John Doe"
                value={this.state.name}
                onChange={evt =>
                  this.setState({ name: evt.target.value, errors: [] })
                }
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                tabIndex={3}
                className={`form-control ${
                  this.state.errors.includes("email") ? "error" : ""
                }`}
                placeholder="john@domain.com"
                value={this.state.email}
                onChange={evt =>
                  this.setState({ email: evt.target.value, errors: [] })
                }
              />
            </div>
          </Col>
          <Col size="half">
            <div className="form-group">
              <label>Company</label>
              <input
                tabIndex={2}
                type="text"
                className={`form-control ${
                  this.state.errors.includes("company") ? "error" : ""
                }`}
                placeholder="My Company Inc."
                value={this.state.company}
                onChange={evt =>
                  this.setState({ company: evt.target.value, errors: [] })
                }
              />
            </div>

            <div className="form-group">
              <label>Job Title</label>
              <input
                tabIndex={4}
                type="text"
                className={`form-control ${
                  this.state.errors.includes("position") ? "error" : ""
                }`}
                placeholder="Vice President"
                value={this.state.position}
                onChange={evt =>
                  this.setState({ position: evt.target.value, errors: [] })
                }
              />
            </div>
          </Col>
        </Group>
        <Group>
          <Col size="whole">
            <div style={{ textAlign: "right", paddingRight: 16 }}>
              <CtaButton
                dark={true}
                onClick={() => this.handleFormSubmit(newsletter)}
              >
                Submit
              </CtaButton>
            </div>
          </Col>
        </Group>
      </>
    )
  }

  render() {
    const columnCount = this.state.isDesktop ? 3 : 1
    console.log({ columnCount })
    return (
      <>
        <Layout wide={true}>
          <SEO
            title="ESG Industry | ESG Data"
            keywords={["ESG Industry", "ESG Data"]}
            description="Here, you get all the news and updates related to ESG Industry, ESG data, reporting and analytics that can be very helpful.  Reach out to us to learn more."
          />
          <TopContainer
            titleLines={["Media"]}
            body="Keeping you up to date with all the latest news from The S Factor Co."
            backgroundImage={img}
            wide={true}
          />

          <Section hasDivider={false} wide={true}>
            <Group>
              <Col size="three-quarters">
                {/* <div class="headingAccent"></div>

                <h4>Upcoming Events</h4>
                <Group>{this.renderNews(eventItems, columnCount)}</Group>
                <div style={{ height: 20 }} /> */}

                <div class="headingAccent"></div>

                <h4>Media</h4>

                <Group>{this.renderNews(mediaItems, columnCount)}</Group>
              </Col>

              <Col size={"quarter"}>
                <div class="sf-news-bg">
                  <div style={{ height: 8 }} />

                  <div class="headingAccent"></div>
                  <h3>S-Factor</h3>
                  {/* <h4>Video</h4>
                  <VideoEmbed youtubeId="S1s4H7bgx4E" /> */}
                  {/* <div style={{ height: 20 }} /> */}
                  {/* <h4>Events &amp; Media</h4>
                  <ul class="media-items">{this.renderMediaItems()}</ul> */}

                  <CtaButton
                    target="_blank"
                    href="https://visitor.r20.constantcontact.com/d.jsp?llr=8qhp7l4ab&p=oi&m=8qhp7l4ab&sit=5l9yrlunb&f=cebffb97-eef1-4cc1-bc3a-e4b16d3783bd"
                  >
                    Subscribe for Updates
                  </CtaButton>
                  <div style={{ height: 20 }} />

                  <h4>Industry News</h4>
                  <Group>{this.renderInternalFeed()}</Group>
                  {/* <Group>{this.renderInternalLoadMoreButton()}</Group> */}

                  <div style={{ height: 20 }} />
                  <h4>Newsletters</h4>
                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: "#ccc",
                    }}
                  />
                  {this.renderNewsItems()}

                  {/* <div style={{ height: 20 }} />
                  <h4>Blog</h4>
                  <p>
                    Follow The S Factor Co.s blog The Real Dirt on Social
                    Impact, here are some of the latest additions:
                  </p>
                  <Group>{this.renderInternalFeed()}</Group>
                  <Group>{this.renderInternalLoadMoreButton()}</Group> */}
                </div>
              </Col>
            </Group>
          </Section>

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal.bind(this)}
            style={modalStyle}
            contentLabel="Example Modal"
            closeTimeoutMS={500}
          >
            <div className="modalContent">
              <div className="modalClose" onClick={this.closeModal.bind(this)}>
                Close
              </div>
              {this.renderModalContent()}
            </div>
          </Modal>
        </Layout>
      </>
    )
  }
}

const modalStyle = {
  overlay: {
    zIndex: 500,
    maxWidth: 620,
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    marginLeft: -6,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "100%",
    // marginRight: '-50%',
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    maxHeight: "100vh",
  },
}

export default NewsPage
